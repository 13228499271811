import {
  Autocomplete,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import API_BASE_URL from "api";
import { axiosInstance } from "api2";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState,useCallback } from "react";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";

const UserEdit = ({ open, onClose, user, onEdit }) => {
   
    
    const [modalData, setModalData] = React.useState(user|| {});
  
    const token = localStorage.getItem("authToken");
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData({
      ...modalData,
      [name]: value,
    });
  };

  const fetchUsers = useCallback(async (role) => {
    try {
      var url = "employees";
      if(role === "instructor" ) {
        url = "instructors";
      }else if (role === "student") {
        url = "applicant-students";
      }
      else{

      }
      const response = await axios.get(`${API_BASE_URL}/${url}`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      setUsers(response.data); // Set the users fetched based on the selected role
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  },[token]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesData = await axiosInstance.get('/roles');
        
        setRoles(rolesData.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchData();
  }, []);

 

  // Set initial selected role and fetch users accordingly
useEffect(() => {
  if (user?.roles[0]?.name) {
    const initialRole = user.roles[0].name;
    setSelectedRole({ name: initialRole }); // Ensure it's an object with a name property
    fetchUsers(initialRole); // Populate users based on the role
  }
}, [user, fetchUsers]);

// Set the selected user once users are fetched
useEffect(() => {
  if (users.length > 0 && user?.target_id) { 
  

    const userToSelect = users.find((u) => Number(user.target_id) === u.id);
    setSelectedUser(userToSelect);

  }
}, [users, user.target_id]); 

  useEffect(() => {
    if (selectedRole) {
      fetchUsers(selectedRole.name);
    }
  },[fetchUsers,selectedRole]);
   


  const handleModalSubmit = async () => {
    setLoading(true); 
    try {

        const dataToSubmit = {
            full_name: modalData.full_name,
            email: modalData.email,
            role: selectedRole ? selectedRole.id : null, // Include the selected role ID
            target_id: selectedUser ? selectedUser.id : null,
          };

       
      const response = await axios.put(`${API_BASE_URL}/users/${modalData.id}`, dataToSubmit, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      if (response.status === 200) {
        onEdit();
        onClose();
        swal({
          title: "Users Updated Successfully!",
          // text: "Course Created Successfully!",
          icon: "success"
        });
        // setModalData({
        //   full_name: "",
        //   email: "",
        //   role: null,
         
        // });
        setSelectedRole(null);
        setSelectedUser(null)
      }
    } catch (error) {
      console.log(error.reponse)
      // setError("An error occurred");
    }finally {
      setLoading(false); // Stop loading
    }
  };
 
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogTitle textAlign={"center"}>
      Edit User
    </DialogTitle>

    <DialogContent>
          
          <MDTypography variant="h5" color="text" fontWeight="bold" my={2}>
            
          </MDTypography>

          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Full Name
            </MDTypography>
            <MDInput
              type="text"
              name="full_name"
              fullWidth
              required
              value={modalData.full_name}
              onChange={handleModalChange}
            />
          </MDBox>

          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Email
            </MDTypography>
            <MDInput
              type="text"
              name="email"
              fullWidth
              required
              value={modalData.email}
              onChange={handleModalChange}
            />
          </MDBox>

          <MDBox mb={2}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              Role
            </MDTypography>
            <Autocomplete
              fullWidth
              options={roles} // options should be a list of role objects
              getOptionLabel={(option) => option?.name || ''} // Add safe fallback in case option is undefined
              onChange={(event, newValue) => {
                setSelectedRole(newValue); // Update selected role state
                setModalData((prev) => ({
                  ...prev,
                  roles: newValue ? [newValue] : [], // Update modalData with the new role (as an array)
                }));
              }}
              value={selectedRole || null}  // Pass the first role in modalData.roles to the Autocomplete
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </MDBox>


          <MDBox mb={2}>

            <Autocomplete
  fullWidth
  options={users}
  getOptionLabel={(option) => {
    if (selectedRole?.name === "instructor") {
      return option.full_name || ''; // for instructors, show full_name
    } else {
      return `${option.first_name} ${option.last_name}` || ''; 
    }
  }}
  onChange={(event, newValue) => {
    setSelectedUser(newValue);
  }}
  value={selectedUser || null}
  renderInput={(params) => <TextField {...params} label="User" />}
/>
          </MDBox>

        
          </DialogContent>
          <DialogActions>
          <MDButton
            variant="gradient"
            size="small"
            color="info"
            sx={{ background: "#00274D" }}
            onClick={handleModalSubmit}
          >
            Submit
          </MDButton>
            <MDButton
              variant="gradient"
              size="small"
              color="black"
              sx={{ background: "grey" }}
              onClick={onClose}
            >
              Close
            </MDButton>
          </DialogActions>
          <LoadingDialog open={loading}/>
    
        </Dialog>
  )
}

export default UserEdit