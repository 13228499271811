import React, { useState,useEffect } from "react";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  addReference,
  deleteReference,
} from "../../../redux/features/referencesSlice"; // Adjust the path as necessary
import { updateStudentInfo } from "../../../redux/features/studentInfoSlice";
import DataTable from "examples/Tables/DataTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DropzoneArea } from "react-mui-dropzone";
import { updatePayment } from "../../../redux/features/paymentSlice";
import API_BASE_URL from "api";
import axios from "axios";
import swal from "sweetalert";
import LoadingDialog from "components/Loading/LoadingDialog";

function ApplicationsPage4() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 

  const student = useSelector((state) => state.studentInfo);
  const studentEmployeementRecords = useSelector(
    (state) => state.studentEmployeementRecords
  );
  const languages = useSelector((state) => state.languages);
  const references = useSelector((state) => state.references);
  const payments = useSelector((state) => state.payment.payment);
  const educationalBackgrounds = useSelector(
    (state) => state.educationalBackgrounds
  );
  // const [error,setError] = useState(null);

  const students = {
    ...student,
    educational_backgrounds: educationalBackgrounds,
    student_employeement_records: studentEmployeementRecords,
    references: references,
    languages: languages,
    payments: payments,
  };


  const [currentReferences, setcurrentReferences] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const referenceList = references;

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({
    full_name: "",
    transaction_id: "",
    phone_number: "",
    payment_amount: "",
    payment_date: "",
    receipt_path: null,
  });



  useEffect(() => {
    if (payments) {
      setModalData({
        full_name: payments[0].full_name,
        transaction_id: payments[0].transaction_id,
        phone_number: payments[0].phone_number,
        payment_amount: payments[0].payment_amount,
        payment_date: payments[0].payment_date,
        receipt_path: payments[0].receipt_path,
      });
    }
  }, [payments]);

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (files) => {
    if (files.length === 0) return; // No files to process

    const file = files[0];
    // Update modalData with the mock file path
    setModalData((prev) => ({
      ...prev,
      receipt_path: file, // Update filePath in modalData
    }));
  };

  const handleModalSubmit = () => {
   
    dispatch(
      updatePayment({
        payment: [
          {
            full_name: modalData.full_name,
            phone_number: modalData.phone_number,
            fee_id: 1,
            payment_amount: parseFloat(modalData.payment_amount),
            payment_date: modalData.payment_date,
            payment_method: "cash", // Adjust as needed
            transaction_id: modalData.transaction_id,
            receipt_path: modalData.receipt_path,
          },
        ],
      })
    );
    handleModalClose();
  };

  

  const handleReferencesChange = (e) => {
    const { name, value } = e.target;
    setcurrentReferences((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    dispatch(
      updateStudentInfo({
        [name]: value,
      })
    );
  };

  const handleAddReference = () => {
    console.log("refrences:",references)
    const existingReference = references.find(ref => ref.email === currentReferences.email);
    console.log("existingReference:",existingReference)

    if (existingReference) {
      // Handle the case where the reference already exists
      alert("Reference with this email already exists");
     
    }
    else if (existingReference || currentReferences.name === "" || currentReferences.phone === ""
      ||currentReferences.email === "") {
       alert("Please add all required fields to continue");
     }
     else{ 
    dispatch(addReference(currentReferences));
    setcurrentReferences({
      name: "",
      relationship: "",
      phone: "",
      email: "",
    });
  }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (references.length < 3) {
      swal({
        title: "Minimum 3 references required",
        text: "Please add at least 3 references before submitting.",
        icon: "warning",
      });
      return; // Prevent form submission
    }
    const formData = new FormData();

    // Append your student data
    Object.entries(students).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          for (const itemKey in item) {
            if (itemKey === "document" && item[itemKey] instanceof File) {
              formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
            }
            if (itemKey === "receipt_path" && item[itemKey] instanceof File) {
              formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
            } else {
              formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
            }
          }
        });
      } else {
        formData.append(key, value);
      }
    });
 

    try {

      const response = await axios.post(
        `${API_BASE_URL}/applicant-student`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", 
          },
        }
      ); 
     
 

      if (response.status === 201) {
        if (response.data[0] === null) {
            swal({
                title: "Application sent Successfully!",
                text:"check your email",
                icon: "success"
            });
            navigate("/authentication/sign-in")
        } else {
            swal({
                title: "Do you want to pay now or later?",
                buttons: {
                    cancel: "Later",
                    confirm: {
                        text: "Pay Now",
                        value: "now",
                    }
                },
                icon: "info"
            }).then((value) => {
                if (value === "now") {
                    window.location.href = response.data[0];
                } else {
                    // Handle if they want to pay later (no redirection)
                    swal({title:"You can pay later from your email sent",
                      icon: "success"


                    });
                }
            });
        }
    } else {
        // Redirect to the payment URL if status is not 201
        window.location.href = response.data.payment_url;
    }
    
    } catch (error) {
      console.error("Error uploading data:", error);
      alert("Something went wrong please check Contact admins")
      navigate("/application-intro");

    }finally {
      setLoading(false); // Stop loading
    }
  };

  const handleBack = () => {
    navigate("/applicationPage3");
  };

  const handleDeleteReference = (row) => {
    const referenceToDelete = {
      email: row.values.email,
      name: row.values.name,
      phone: row.values.phone,
    };
    
    dispatch(deleteReference(referenceToDelete));
  };



  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Phone Number", accessor: "phone",showSortIcons:false },
    { Header: "Email", accessor: "email",showSortIcons:false },
    {
      Header: "Actions",
      accessor: "actions",
      showSortIcons:false,
      Cell: ({ row }) => (
        <MDButton
          variant="outlined"
          color="error"
          onClick={() => handleDeleteReference(row)}
        >
          Delete
        </MDButton>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Card>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDTypography variant="h5" fontWeight="bold" gutterBottom>
              References
            </MDTypography>
            <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
              Please give me the names and addresses of three references. Dont
              include relatives
            </MDTypography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    Name <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="text"
                    name="name"
                    label="Name"
                    fullWidth
                    value={currentReferences.name}
                    onChange={handleReferencesChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    Email <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="email"
                    name="email"
                    // label="Emails"
                    fullWidth
                    value={currentReferences.email}
                    onChange={handleReferencesChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={2}>
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </MDTypography>
                  <MDInput
                    type="number"
                    name="phone"
                    // label="Phone Number"
                    fullWidth
                    value={currentReferences.phone}
                    onChange={handleReferencesChange}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={4} sx={{ my: 5 }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleAddReference}
                >
                  Add Reference
                </MDButton>
              </Grid>
            </Grid>
            <DataTable table={{ columns, rows: referenceList }} />

            <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
              Do you have any physical challenges
            </MDTypography>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Please mention your experience{" "}
                <span style={{ color: "red" }}>*</span>
              </MDTypography>
              <MDInput
                type="text"
                name="physical_challenge"
                label="Experience"
                fullWidth
                multiline
                rows={5}
                value={student.physical_challenge}
                onChange={handleChange}
              />
            </MDBox>

            <MDTypography variant="h6" fontWeight="semibold" gutterBottom>
              Other Information
            </MDTypography>
            <MDBox mb={2}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                Please give any information you think might be important for
                consideration
                <span style={{ color: "red" }}>*</span> by Academic Affairs
              </MDTypography>
              <MDInput
                type="text"
                name="other_information"
                label="Information"
                fullWidth
                multiline
                rows={5}
                value={student.other_information}
                onChange={handleChange}
              />
            </MDBox>
            
            <Grid container spacing={2} sx={{ mt: 4 }}>
    <Grid item xs={12} md={6}>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start">
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleModalOpen}
          sx={{ mb: 2 }}
        >
          Payment Offline
        </MDButton>
        <MDTypography variant="body2" fontWeight="normal">
          Submitting will redirect to Chapa for payment
        </MDTypography>
      </MDBox>
    </Grid>
    <Grid item xs={12} md={6}>
      <MDBox
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'flex-end' },
          alignItems: { xs: 'stretch', md: 'center' },
          height: '100%',
        }}
      >
        <MDButton
          variant="gradient"
          color="light"
          size="small"
          onClick={handleBack}
          sx={{width: { xs: '50%', md: 'auto' }, mb: { xs: 2, md: 0 }, mr: { md: 2 } }}
        >
          Go Back
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleSubmit}
          sx={{width: { xs: '50%', md: 'auto' },}}
        >
          Submit
        </MDButton>
      </MDBox>
    </Grid>
  </Grid>
</MDBox>


          
          <Dialog
              open={openModal}
              onClose={handleModalClose}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>Payment Offline</DialogTitle>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {/* Left Side - Upload Section */}
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <DropzoneArea
                        onChange={handleFileChange} // Handle file uploads here
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              color: "blue",
                              fontSize: "18px",
                            }}
                          >
                            Drag and drop an image here or click
                          </div>
                        }
                        filesLimit={1} // Adjust based on your needs
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        initialFiles={modalData.receipt_path ? [modalData.receipt_path] : []}
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* Right Side - Form Section */}
                    <MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Full Name <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="full_name"
                          fullWidth
                          value={modalData.full_name}
                          onChange={handleModalChange}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Transaction Number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="transaction_id"
                          fullWidth
                          value={modalData.transaction_id}
                          onChange={handleModalChange}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="phone_number"
                          fullWidth
                          value={modalData.phone_number}
                          onChange={handleModalChange}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Amount <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="payment_amount"
                          fullWidth
                          value={modalData.payment_amount}
                          onChange={handleModalChange}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography
                          variant="caption"
                          color="text"
                          fontWeight="medium"
                        >
                          Date <span style={{ color: "red" }}>*</span>
                        </MDTypography>
                        <MDInput
                          type="date"
                          name="payment_date"
                          fullWidth
                          value={modalData.payment_date}
                          onChange={handleModalChange}
                        />
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton onClick={handleModalSubmit}  variant="contained"
                       size="small"
                       color="info"
                       sx={{background:"#00274D"}}>Submit</MDButton>
                <MDButton onClick={handleModalClose} variant="variant" 
                       size="small"
                       color="black"
                       sx={{background:"grey"}} >Cancel</MDButton>
              </DialogActions>
            </Dialog>
        </MDBox>

                  
      </Card>
      <LoadingDialog open={loading} />
    </DashboardLayout>
  );
}

export default ApplicationsPage4;
